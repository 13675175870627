import React, {useEffect} from 'react';
import {EPageUrlType} from '@beyable/sdk';
import {beyable} from './libs/beyable'
import logo from './logo.svg';
import './App.css';

function App() {
  useEffect(() => {
    try {
      beyablePageView();
    } catch (error) {
      console.error(error);
    }
  },[])

  /**
   * @description Sends "Page view".
   * @returns {Promise<void>}
   */
  const beyablePageView = async () => {
    beyable.page.setAttributes({
      urlType: EPageUrlType.HOME,
      homePage: {
        tags: ['homepage', 'corporate'],
      },
      pageReferrer: 'https://www.google.com',
      url: 'http://localhost',
    });

    await beyable.sendPageview();
  }

  return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
  );
}

export default App;
